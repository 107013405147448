<template>
  <div>
    <v-container>
      <h2 class="grey--text text--darken-1 mt-5 mb-5">Projects</h2>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">#</th>
              <th class="text-left">PROJECT</th>
              <th>
                <v-btn small class="primary" @click="addProject">Add Project</v-btn>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in projects" :key="item.id">
              <td>{{ index + 1 }}</td>
              <td style="text-transform: uppercase;">{{ item.title }}</td>
              <td>
                <v-icon @click="viewProject(item._id)" class="blue--text">mdi-magnify</v-icon>
                <v-icon class="ml-5 green--text" @click="editProject(item._id)">mdi-pencil</v-icon>
                <v-icon class="ml-5 red--text">mdi-delete</v-icon>
            </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-container>
  </div>
</template>

<script>
import Axios from "axios";
import { BASE_URL } from "../config";
export default {
  data() {
    return {
      projects: [],
    };
  },
  mounted() {
    this.fetchProjects();
  },
  computed : {
    filteredProjects(){
        
    }
  },
  methods: {
    addProject(){
      this.$router.push('/add-project')
    },
    async fetchProjects() {
      let url = BASE_URL + "/project";
      let { data } = await Axios.get(url);
      this.projects = data;
    },
    viewProject(id){
        this.$router.push('/view-project/'+id)
    },
    editProject(id){
      this.$router.push('/edit-project/'+id)
    }
  },
};
</script>